import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'
import { motion, useViewportScroll, transform } from 'framer-motion'

import { Layout, PageHero, PageSection, FeaturedEngagements } from '../components'

import { container, padding, colours, type, bgImage, pagePaddingTop, wrapper } from '../styles/global'
import { parseACF } from '../utils'
import { useScrollAnim } from '../utils/anim'

const Speaking = (props) => {
	const data = parseACF(useStaticQuery(query), 'allWordpressInfopages', true)

	const renderSections = () => {
		if (!data.sections) return;

		const items = data.sections.map((item, i) => {
			if (!item.display) return;

			return (
				<PageSection 
					key={i}
					{...item}
				/>
			)
		})

		return items
	}
	
	return (
		<Layout
            meta={data.seo}
        >
			<Wrapper>
                <PageHero
                    data={data}
                />

                <Container>
                   {renderSections()}
                </Container>

				{data.featured_engagments && (
					<FeaturedEngagements 
						slides={data.featured_engagments} 
						heading={data?.featured_engagments_heading}
					/>
				)}
			</Wrapper>
		</Layout>	
	)
}


// Shared

const Heading = styled(motion.h2)``
const Subheading = styled.h3``
const Text = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled(motion.div)`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

const Video = styled(ReactPlayer)``

// Layout

const Wrapper = styled.div`
	${wrapper}
`

const Container = styled.div`
	${container}
	${padding}
	${pagePaddingTop}
	
	display: flex;
	flex-direction: column;
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "speaking" } }) {
            nodes {
                title
                slug
                acf_json
            }
        }
    }
`

export default Speaking